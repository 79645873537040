import EnterRoutes from '@/router/modules'
import { configRouter } from './configRouter';
import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";

function InitRouter(app) {
  mountRouter(app, EnterRoutes)
}
// 路由挂载
function mountRouter(app, EnterRoutes) {
  EnterRoutes.push({
    path: "/:catchAll(.*)",
    redirect: "/404"
  })
  const router = createRouter({
    history: createWebHistory(),
    routes: EnterRoutes,
    linkActiveClass: 'active'
  });
  configRouter(router)
  app.use(router)
}

export default InitRouter