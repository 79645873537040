import App from '@/App.vue';
import {
    createApp, vModelCheckbox,
    nextTick
} from 'vue';
import pinia from '@/pinia'
import {
    Toast
} from 'vant';
import 'vant/lib/index.css';
import '@/css/van.scss';
import '@/css/el.scss';
import store from "@/store/index"
import 'element-plus/dist/index.css';
import ElementPlus from 'element-plus';
import InitRouter from '@/router/InitRouter';
import locale from 'element-plus/lib/locale/lang/zh-cn';
import * as ElementPlusIconsVue from '@element-plus/icons-vue' //ele 图标库
import Vue3SeamlessScroll from "vue3-seamless-scroll";
// 数据持久化插件
import piniaPersist from 'pinia-plugin-persist'
import {
    drag
} from '@/utils/drag.js'
import * as filters from "@/filters/EnterFilters.js" // filters
import '@/assets/iconfont/iconfont.css';
import LazyLoad from 'lazy-load-vue3'
import installDirective from './directive'

// 判断用户是否登录
let token = localStorage.getItem('token')
// 拿出来 本地存储的值
if (token) {
    // 拿到个人信息
    store.dispatch('getUserInfo', {
        token
    })
}
const app = createApp(App);
installDirective(app)
// 图片懒加载
app.use(LazyLoad, {
    loading: './assets/about/masker.png'
})


// 滚动指令
app.directive('scroll', {
    /**
     *  1/ 外层盒子移出隐藏，内层盒子横向排列
     *  2. 加上指令
     *  3. 传进去外层盒子，固定宽度的盒子
     *  4. 他里面有两个孩子元素，最后一个孩子元素只有一个外壳，里面不设置内容
     */
    mounted(el) {
        var speed = 30 //控制数字快慢
        let parentNode = el;
        let picture1 = parentNode.firstElementChild
        let picture2 = parentNode.lastElementChild

        setTimeout(() => {
            // picture2.innerHTML = picture1.innerHTML
        }, 2000);

        function Marquee() {
            if (parentNode.scrollLeft >= picture1.scrollWidth) {
                parentNode.scrollLeft = 0
            } else {
                parentNode.scrollLeft++
            }
        }
        var MyMar = setInterval(Marquee, speed)
        parentNode.onmouseover = function () {
            clearInterval(MyMar) //鼠标移上时清除定时器达到滚动停止的目的
        }
        parentNode.onmouseout = function () { //鼠标移开时重设定时器
            MyMar = setInterval(Marquee, speed)
        }
    }
})




// 在线客服过滤图片
app.config.globalProperties.$filters = filters // 挂在到全局 fuilter
// 挂在 $msg
app.config.globalProperties.$msg = {
    success: (msg) => ElMessage.success({
        message: String(msg),
        showClose: true,
    }),
    error: (msg) => ElMessage.error({
        message: String(msg),
        showClose: true,
    }),
    warn: (msg) => ElMessage.warning({
        message: String(msg),
        showClose: true,
    }),
    confirm: (msg, title, btn = {
        yes: "确定",
        no: "取消"
    }, options) =>
        ElMessageBox.confirm(msg, title, {
            confirmButtonText: btn.yes || '确定',
            cancelButtonText: btn.no || '取消',
            ...options
        })
}

pinia.use(piniaPersist) // 持久化pinia
app.directive('drag', drag)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
const router = InitRouter(app) // 获取路由 
app.use(router).use(store).use(ElementPlus, {
    locale
}).use(pinia).use(Vue3SeamlessScroll).use(Toast).mount('#web')