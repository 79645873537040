import script from "./App.vue?vue&type=script&setup=true&lang=js"
export * from "./App.vue?vue&type=script&setup=true&lang=js"

import "./css/commonStyle.css?vue&type=style&index=0&lang=css"
import "./css/reset.css?vue&type=style&index=1&lang=css"
import "./css/global.css?vue&type=style&index=2&lang=css"
import "./App.vue?vue&type=style&index=3&id=b04aee78&lang=scss"

const __exports__ = script;

export default __exports__