import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, getCurrentInstance } from "vue";
import { useImageHandler } from "@/hooks/index.js";
import { ElMessage } from 'element-plus'

export default {
  props: {
  type: Array
},
  emits: ["success"],
  setup(__props, { emit: $emits }) {

const props = __props


const {
  proxy: { $msg },
} = getCurrentInstance();
const { fileToBase64, compressImage } = useImageHandler;

const InputRef = ref();
const upload_bool = ref(true);
async function uploadChange(e) {
  if (!upload_bool.value) return $msg.error("请勿频繁点击");
  upload_bool.value = false;
  let file = e.target.files[0];
  const type = file.type
  if (!checkImgType(type)) {
    ElMessage.warning('图片只能上传: jpg, jpeg, png 的格式喔')
    return
  }
  const img = await fileToBase64(file);
  file.size > 50000
    ? compressImage(img.src, { width: 400, file: img }, (base) =>
      $emits("success", { src: img.src, base: base })
    )
    : $emits("success", img);
  InputRef.value.value = "";
  upload_bool.value = true;
}

// 检测图片的格式是否符合
const checkImgType = (type) => props.type.some(v => type.includes(v))

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    id: "me-upload",
    onClick: _cache[0] || (_cache[0] = $event => (InputRef.value.click()))
  }, [
    _withDirectives(_createElementVNode("input", {
      type: "file",
      accept: "image/*",
      onChange: uploadChange,
      ref_key: "InputRef",
      ref: InputRef
    }, null, 544), [
      [_vShow, false]
    ]),
    _renderSlot(_ctx.$slots, "icon")
  ]))
}
}

}